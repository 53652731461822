<ng-container *ngIf="accessibility$ | async as accessibility">
  <mat-toolbar style="background: transparent;justify-content: end">
    <app-accessibility ></app-accessibility>
  </mat-toolbar>
</ng-container>
<form (ngSubmit)="login()" #loginForm1="ngForm"
      fxLayoutAlign="center" fxLayout="column"
      *ngIf="!modeAnonyme">
  <mat-card class="alert-warning" *ngIf="message">
    <mat-card-content fxLayout="row">
      {{message.message}}
      <button mat-icon-button class="button-warning" fxFlex fxLayoutAlign="end" (click)="closeAlert()" data-cy="btn-external-login-close">
        <mat-icon>close</mat-icon>
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card class="panel-single">

    <div class="mat-subheading-2 app-cm_login__title">{{ applicationName }}<br/>Connectez-vous</div>

    <mat-card-content>
      <mat-form-field fxLayout="row">
        <mat-icon matPrefix>person</mat-icon>
        <input matInput placeholder="Identifiant"
               name="username"
               required
               type="text"
               [(ngModel)]="model.username" data-cy="input-external-login-username">
      </mat-form-field>

      <mat-form-field fxLayout="row">
        <mat-icon matPrefix>lock</mat-icon>
        <input matInput placeholder="Mot de passe" name="password" type="password" required
               [(ngModel)]="model.password" data-cy="input-external-login-pwd">

      </mat-form-field>
    </mat-card-content>
    <mat-card-actions fxLayout="column">
      <div fxLayout="row" fxFlexAlign="start" *ngIf="application === accessToApplication.ater_candidature">
        <a href="javascript: void(0);" (click)="downloadFile()" style="padding-bottom: 10px;font-size: 12px;">
          Télécharger le mode opératoire
        </a>&nbsp;&nbsp;
        <i class="fa fa-info-circle fa-lg" aria-hidden="true" fxLayoutAlign="end"
           matTooltip="Télécharger le mode opératoire pour l'initialisation du mot de passe."></i>
      </div>
      <a href="javascript: void(0);" (click)="resetPwd()" style="padding-bottom: 10px;font-size: 12px;" fxLayoutAlign="end"> Mot de passe oublié</a>
      <button mat-raised-button
              [disabled]="! canValidate()"
              type="submit"
              [color]="'primary'" data-cy="btn-external-login-connection">Connexion
      </button>
    </mat-card-actions>
  </mat-card>
</form>
