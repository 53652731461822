import {Component, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {accessToApplication, AccessToApplication} from "@Common/core/services";
import {Router} from "@angular/router";
import {AccessibilityService} from "@Common/widgets/accessibility/services/accessibility.service";

@Component({
  selector: 'app-cm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  defaultLogin = true;
  parentUrl: string = '/';
  accessibility$ = this.accessibilityService.currentAccessibility;

  constructor(
    @Optional() @Inject(accessToApplication) private application: AccessToApplication,
    private router: Router,private accessibilityService: AccessibilityService) {
    this.parentUrl = this.router.getCurrentNavigation().extras.state?.parentUrl ?? this.parentUrl;
    console.log('LoginComponent inject application:', this.application, this.parentUrl);
    if (this.application) {
      this.defaultLogin = false;
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
