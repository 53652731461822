import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Message} from "@Services/message/message.model";
import {Login} from "@Common/core/login/login.model";
import {HttpErrorResponse} from "@angular/common/http";
import {AuthService} from "@Services/auth";
import {Router} from "@angular/router";
import {SubSink} from "@Common/core/utils/subsink";
import {Observable} from "rxjs";


@Component({
  selector: 'app-default-login',
  templateUrl: './default-login.component.html',
  styleUrls: ['./default-login.component.scss'],
})
export class DefaultLoginComponent implements OnInit, OnDestroy {
  @Input() parentUrl;
  @Input() accessibility$: Observable<any>;
  message: Message;
  modeAnonyme = false;
  model = <Login>{};
  loading = false;
  subSink = new SubSink();

  constructor(private authService: AuthService, private router: Router) {}


  ngOnInit() {

    if (this.authService.loggedIn()) {
      this.router.navigate([this.parentUrl]);
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  closeAlert() {
    this.message = null;
  }

  toggleAnonime() {
    this.modeAnonyme = !this.modeAnonyme;
  }

  canValidate() {
    if (this.loading) {
      return false;
    }
    return !(!this.model.username || !this.model.password);
  }

  login() {
    this.loading = true;
    this.subSink.sink = this.authService.login(this.model).subscribe(data => {
        if (data.token) {
          this.loading = false;
          this.authService.authenticated(data);
          this.router.navigate([this.parentUrl]);
        }
      },
      error => {
        this.loading = false;
        if (error instanceof HttpErrorResponse && error.status === 400) {
          // let message =  error.error.non_field_errors[0] || error.error['password'] || error.error['username'];
          const message = error.error.non_field_errors[0];
          this.message = {
            message: message,
            label: '',
            color: 'red',
            icon: 'error'
          };
        } else {
          throw error;
        }
      }
    );
  }

  loginAnonym() {
    this.router.navigate(['/public']);
  }
}
