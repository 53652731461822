export const environment = {
  production: true,
  env: 'test',
  baseUrl: 'https://api-test.univ-paris8.fr',
  host: 'api-test.univ-paris8.fr',
  codEtu: '',
  urlSentry: 'https://88787bd0fd634a21ad7e6780d3043a28@sentry.univ-paris8.fr/5',
  userLdapTest: '',
  numeroEmploiTest: '',
  monacoConfig:  {
    baseUrl: '/admin/assets', // configure base path for monaco editor
  },
  monacoHelpdeskConfig:  {
    baseUrl: '/helpdesk/assets', // configure base path for monaco editor
  }

};
